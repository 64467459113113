<template>
  <div class="m2">
    <h5 class="text-center">{{ title }}</h5>
    <table class="table__border--gradient table--frontpage">
      <thead class="table__head--gradient">
        <th>Business Unit</th>
        <th>Aantal</th>
      </thead>
      <tbody>
        <tr v-for="(item, key) in delivered_data" :key="key" class="table__row--color">
          <td>{{ item.naam }}</td>
          <td class="table__data--right">{{ item.aantal }}</td>
        </tr>
      </tbody>
      <tfoot>
        <th>Totaal:</th>
        <th class="table__data--right">{{ delivered_total }}</th>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    delivered_data: Array,
    delivered_total: Number,
  },
};
</script>
