var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_container"},[_vm._m(0),_c('hr',{staticClass:"m-1"}),_vm._l((_vm.files),function(category,key1){return _c('div',{key:key1},[_c('h6',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(key1))])]),_vm._l((category),function(item,key2){return _c('a',{key:key2,staticClass:"clickable_file",attrs:{"target":"_blank","href":`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/intranet_bestanden/${item.filename}`,"download":""}},[(
        item.permissions == null ||
        _vm.checkroles(
          item.permissions.split(','),
          _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles
        )
      )?_c('div',{staticClass:"intranet_file"},[_vm._v(" "+_vm._s(item.displayname)+" ")]):_vm._e()])})],2)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-center m1"},[_c('strong',[_vm._v("Bestanden")])])
}]

export { render, staticRenderFns }