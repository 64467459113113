<template>
  <div class="list_container">
    <h5 class="text-center m1"><strong>Bestanden</strong></h5>
    <hr class="m-1">
    <div v-for="(category, key1) in files" :key="key1">
      <h6 class="text-center"><strong>{{ key1 }}</strong></h6>
    <a
      target="_blank"
      class="clickable_file"
      v-for="(item, key2) in category"
      :key="key2"
      :href="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/intranet_bestanden/${item.filename}`"
      download
    >
      <div
        class="intranet_file"
        v-if="
          item.permissions == null ||
          checkroles(
            item.permissions.split(','),
            msalInstance.getAllAccounts()[0].idTokenClaims.roles
          )
        "
      >
        {{ item.displayname }}
      </div>
    </a>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    files: [String, Array, Object],
  },
};
</script>