<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>

      <div class="flexbox spaceevenly table--container">
        <AnniversaryBanner />
        <BirthdayTable :birthdays="birthdays" :title="`Verjaardagen`" :date="day_number" />
        <DeliveredTable :delivered_data="delivered_data" :delivered_total="delivered_total" :title="`Afleveringen`" />
        <DeliveredPerMonthTable :delivered_per_month_data="delivered_per_month_data" :title="`Afleveringen per maand`" />
      </div>
      <div class="flex">
        <div class="flex-grow-1 news__block--wrapper">
          <div v-for="item in news" :key="item.nummer" class="news--items">
            <div class="news--body">
              <h5 class="news--title">{{ item.titel }}</h5>
              <p class="news--text" v-html="item.tekst"></p>
            </div>
          </div>
        </div>
        <div class="flex _column" >
          <div class="list_container p-1">
            <div class="m-1">
              <h6 class="nospaceunder"><strong>BHV</strong></h6>
              <strong>Intern:</strong> 8123
            </div>
            <hr class="m-1">
            <div class="m-1">
              <h6 class="nospaceunder"><Strong>Helpdesk ICT</Strong></h6>
              <strong>Intern:</strong> 0183 66 (8125) (Spoed)<br />
              <strong>Email:</strong> help@kleyn.com
            </div>
          </div>
          <FileListTable :files="files" />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.nospaceunder {
  margin: 0px !important;
}
</style>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading";
import DeliveredTable from "@/components/frontpage/DeliveredTable";
import DeliveredPerMonthTable from "@/components/frontpage/DeliveredPerMonthTable";
import BirthdayTable from "@/components/frontpage/BirthdayTable";
import moment from "moment";
import FileListTable from "@/components/frontpage/FileListTable.vue";
import AnniversaryBanner from "@/components/frontpage/AnniversaryBanner.vue"

export default {
  name: "Home",
  components: {
    Loading,
    DeliveredTable,
    DeliveredPerMonthTable,
    BirthdayTable,
    FileListTable,
    AnniversaryBanner,
  },
  data: () => ({
    loading: true,
    error: null,
    news: null,
    week_number: null,
    month_number: null,
    year_number: null,
    day_number: null,
    delivered_data: null,
    delivered_total: null,
    delivered_per_month_data: null,
    birthdays: null,
    roles: null,
    files: null,
  }),
  created() {
    const date = moment();
    this.week_number = date.week();
    this.year_number = date.year();
    this.month_number = date.month() + 1;
    this.day_number = date.date();

    //when page is loaded this function is called.
    this.loading = true;

    request("frontpage/news", "GET").then(({ news, error }) => {
      if (error) this.error = error;
      this.news = news;
    });

    request(`frontpage/delivered/${this.year_number}`, "GET").then(
      ({ delivered, delivered_total }) => {
        this.delivered_data = delivered;
        this.delivered_total = delivered_total;
      }
    );

    request(
      `frontpage/delivered-per-month/${this.year_number}/${this.month_number}`,
      "GET"
    ).then(({ delivered_per_month }) => {
      this.delivered_per_month_data = delivered_per_month;
    });

    request(
      `frontpage/birthday-per-week/${this.year_number}/${this.week_number}`,
      "GET"
    ).then(({ birthdays }) => {
      this.birthdays = birthdays;

    });

    request(`get-files`, "GET").then(({ files }) => {
      this.files = files;
      this.loading = false;
    });
  },
};
</script>
<style>
.news--text img {
  max-width: 400px;
}

.news--text>p {
  margin: 0 !important;
}
</style>
