<template>
  <div class="w100">
    <div v-for="(item, key) in anniversaries" :key="key" class="text-center anniversary--banner-div">
        <span v-for="(person, key2) in item" :key="key2" class="anniversary--banner-span">Gefeliciteerd {{ person.voornaam }} {{ person.tussenvoegsel }} {{ person.achternaam }} met je {{ key }} jarig jubileum!<br /></span>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";

export default {
  data: () => ({
    anniversaries: null,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request(`frontpage/anniversary`, "GET").then(({ anniversaries }) => {
        this.anniversaries = anniversaries;
      });
    },
  },
};
</script>
