<template>
  <div class="m2">
    <h5 class="text-center">{{ title }}</h5>
    <table class="table__border--gradient table--frontpage">
      <thead class="table__head--gradient">
        <th>Dag</th>
        <th>Datum</th>
        <th>Namen</th>
      </thead>
      <tbody>
        <tr v-for="(item, key) in birthdays" :key="key" class="table__row--color">
          <td v-if="item.dag == date" class="table--body-gray">
            {{ item.datename }}
          </td>
          <td v-else>{{ item.datename }}</td>
          <td  v-if="item.dag == date" class="table__data--right">
            {{ item.dag }}
          </td>
          <td v-else class="table__data--right">{{ item.dag }}</td>
          <td v-if="item.naam && Array.isArray(item.naam)">
            <span v-for="(names, key) in item.naam" :key="key">
              {{ names }}<br/>
            </span>
          </td>
          <td v-else>{{ item.naam }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    birthdays: Object,
    title: String,
    date: Number,
  },
};
</script>
